import React, { useEffect, useState } from 'react'
import { HeaderMenu } from '../../component/HeaderMenu'
import ModalMakeMeAFileByDate from '../modal/ModalMakeMeAFileByDate.comp'
import './MainStyle.css'
import { Avatar, Badge, Box, Drawer, LinearProgress, Rating, TextField, Tooltip, Typography } from '@mui/material'
import LoginContext from '../../context/login_context/LoginContext'
import TaskComp from '../../component/TaskComp.component'
import { useContext } from 'react'
import AsyncSelect from 'react-select/async';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

// icons
import ChecklistIcon from '@mui/icons-material/Checklist';
import TimelineIcon from '@mui/icons-material/Timeline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SaveIcon from '@mui/icons-material/Save';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ConstructionIcon from '@mui/icons-material/Construction';
import VisibilityIcon from '@mui/icons-material/Visibility';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import RefreshIcon from '@mui/icons-material/Refresh';
import { PiDownloadBold } from "react-icons/pi";
import { FaListCheck } from "react-icons/fa6";
import { IoLayersOutline } from "react-icons/io5";
import FilterAltIcon from '@mui/icons-material/FilterAlt';//ico filter
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';//ico without filter
import AddIcon from '@mui/icons-material/Add';

import { GetOts } from '../../api/GetOts.api'
import { GetTasks } from '../../api/GetTasks.api'
import { StatusToRevcn } from '../../api/StatusToRevcn.api'
import { ModalLoaded } from '../../component/ModalLoaded.component'
import { Put_AddNote } from '../../api/Put_AddNote.api'
import { useNavigate } from 'react-router-dom'
import { Close, Label } from '@mui/icons-material'
import { CreaSolicitud } from '../../api/CreaSolicitud.api'
import { URL_SRV_RAIZ } from '../../router/Url'
import { SolicitudCreated } from '../../api/OT/SolicitudCreated.api'
import { ClosedOt } from '../../api/OT/ClosedOt.api'

export default function Ot() {

	const MySwal = withReactContent(Swal)

	/** variables y mutadores */
	const {LoginState,SetLogin,CloseLogin,GetLoginSession} = useContext(LoginContext);
	// console.log(LoginState);
	const [VisibleModal, setVisibleModal] = useState(false)
	const [NotaEdit, setNotaEdit] = useState("")
	const [valueRating, setValueRating] = React.useState(0);
	const [OtsBault, setOtsBault] = useState([]);
	const [OtsBaultBKP, setOtsBaultBKP] = useState([]);
	const [TasksBault, setTasksBault] = useState([]);
	const [OtsSelected, setOtsSelected] = useState([]);

	const [MenuFilter, setMenuFilter] = useState(false);
	const [FechaFilter, setFechaFilter] = useState('');
	const [OTFilter, setOTFilter] = useState('');

	const [OBSOT, setOBSOT] = useState({});

	const [ModalLoadded, setModalLoadded] = useState(false);
	const [Texto, setTexto] = useState('');

	const [isMakeSoli, setisMakeSoli] = useState({})//0:oculto, 1:visible, 2: solicitud creada

	const [SelMaq, setSelMaq] = useState('');
	const [SelMaqMulti, setSelMaqMulti] = useState({});

	const navigate = useNavigate();
	
	/** MUTADORES DE ESTADO DRAWER */
	const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

	const promiseOptionsEquipos = async (inputValue) =>
    new Promise(async(resolve) => {
      if(inputValue.length >= 3){
        let query = await fetch(URL_SRV_RAIZ+'getequipos_fracttal',{
          method: "POST",
          headers: {
            'authorization': "marcuxo",
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            TEXT:inputValue.toUpperCase(),
            PLANTA: LoginState.planta.toUpperCase()
          })
        })

        let responsio = await query.json();
        // console.log(responsio)
        resolve(responsio.item)
      }else{
        resolve(null)
      }
    
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
		setOBSOT({})
		// setisMakeSoli(0)
		setNotaEdit("")
		setOtsSelected([])
		setState({ ...state, [anchor]: open });
		setSelMaq('')
  };
	/** Funciones y metodos */
		/** obtiene las OTs ligadas al rut  */
	const ObtainOtsApi = async () => {
		setTexto('Se estan Cargando las OTs')
		await setModalLoadded(true)
		const ots = await GetOts({codigo:LoginState.rut})
		// console.log(ots);
		let hash = {};

		// elimina las OT repetidas
		let elfiltro = await ots.filter(o =>  hash[o.wo_folio] ? false : hash[o.wo_folio] = true)
		// console.log(elfiltro);

		// ordena por wo_folio(numero de OT)
		let orden = await elfiltro.sort((a, b) => b.id_work_order - a.id_work_order )
		// console.log(orden);
		setOtsBaultBKP(orden);
		setOtsBault(orden);
		await setModalLoadded(false)
	}
	 /** abre el menu con las tareas de la ot seleccionada */
	const GetTAsksApi = async (wofolio) => {
		const tasks = await GetTasks({wofolio})
		setTasksBault(tasks)
		// console.log('tasks',tasks);
	}

	/** funcion que crea una solicitud de tarea */
	const creaSolictudes = async ({observacion, tarea, agp, tipo_tarea, Clasificacion_2, Clasificacion_1, solicitante, OT, index}) => {
		setTexto('Se estan Creando la Solicitud de Trabajo')
		await setModalLoadded(true)
		const makeSoli = await CreaSolicitud({// ***************************************** CREA SOLICITUD DE TRABAJO *****************************************
		// console.log({
			code: agp,// AGP LIGADO A LA TAREA
			description: "OBSERVACIONES: "+tarea,// OBSERVACIONES + NOMBREE DE LA TAREA
			comment: observacion,// NEGATICO + OBSERVACION OBLIGATORIA
			name: solicitante,// NOMBRE USUARIO LOGUEADO
			reference: OT// NUMERO DE OT
		})

			// una vez creada la solciitud se debe enviar la OT a finalizada y guardar en la base de datos
			console.log("se envio a crear solicitud")
			await SolicitudCreated({
				code: agp,// AGP LIGADO A LA TAREA
				description: "OBSERVACIONES: "+tarea,// OBSERVACIONES + NOMBRE DE LA TAREA
				comment: observacion,// NEGATICO + OBSERVACION OBLIGATORIA
				name: solicitante,// NOMBRE USUARIO LOGUEADO
				reference: OT// NUMERO DE OT
			})//guarda en base de datos la solicitud creada

			console.log("se creo solicitud")
			await setisMakeSoli({...isMakeSoli,[index]:2})//2: solicitud creada
			await setOBSOT({...OBSOT,[index]:''})
			await setSelMaq('')
			await setModalLoadded(false)
			// alert('Para finalizar la OT debe crear la solicitud de trabajo y luego precionar en el boton cerrar OT.')
	}

	/** agrega el activo seleccionado al textarea */
	const addActiveToTextArea = async ({index}) => {
		await setSelMaqMulti({...SelMaqMulti, [index]:SelMaq[index].value})
		let txt = '*'+SelMaq[index].label+'; '
		// console.log(SelMaq[index])
		await setOBSOT({...OBSOT,[index]:txt})
		await setSelMaq({...SelMaq,[index]:""})
	}

		// filtra por fecha las ot's
	const FiltroPorFecha = async () => {
		if(FechaFilter!== ''){
			if(OTFilter !== ''){
				// console.log('filtro fecha ot')
				let filterFecha = await OtsBaultBKP.filter(fl=> fl.date_maintenance.startsWith(FechaFilter) && String(fl.wo_folio).match(OTFilter.toUpperCase()))
				setOtsBault(filterFecha)
				setMenuFilter(!MenuFilter)
			}else{
				// console.log('filtro fecha')
				let filterFecha = await OtsBaultBKP.filter(fl=> fl.date_maintenance.startsWith(FechaFilter))
				setOtsBault(filterFecha)
				setMenuFilter(!MenuFilter)
			}
			return
		}
		if(OTFilter !== ''){
			// console.log('numero ot')
			let filterFecha = await OtsBaultBKP.filter(fl=> String(fl.wo_folio).match(OTFilter.toUpperCase()))
			setOtsBault(filterFecha)
			setMenuFilter(!MenuFilter)
			return
		}
		
	}

	const GenerarPDF_NOW = async (otData) => {
		let {wo_folio} = otData
		setTexto('Estamos Generando el PDF para usted.')
		await setModalLoadded(true)
		const tasks = await GetTasks({wofolio:wo_folio})
		// console.log('tasks',tasks, wo_folio);
		await navigate("/pdf", { state: { OT: otData, TASK: tasks } });
		await setModalLoadded(false)
	}
	
	const MenuCrud = async (wofolio,ots) => {
		setOtsSelected([ots]);
		setTexto('Se estan Cargando las tareas.')
		await setModalLoadded(true)
		// console.log('WO_FOLIO',wofolio);
		await GetTAsksApi(wofolio)
		// toggleDrawer('right', true)
		setState({ ...state, ['right']: true });
		await setModalLoadded(false)
	}

	/** CIERRA LA OT Y DA PASO A LA CREACION DE SOLICITUDES */
	const HandleChangeStatus = async (wo_folio) => {
		Swal.fire({
			title: `Esta seguro de Cerrar la ${wo_folio}?`,
			showDenyButton: true,
			showCancelButton: true,
			confirmButtonText: "Crear Solicitudes de Trabajo",
			denyButtonText: `Cerrar OT`,
			cancelButtonText: "Cancelar",
		}).then(async (result) => {
			/* Read more about isConfirmed, isDenied below */
			if (result.isConfirmed) {
				let obj_ = {}
				for (let ii = 0; ii < TasksBault.length; ii++) {
					// console.log("========>",ii);
					obj_[ii] = 1//({...isMakeSoli,[ii]:0})
				}
				setisMakeSoli(obj_)
			} else if (result.isDenied) {
				/** generar logica para enviar la OT a finalizada y guarda en base de datos */
				console.log("1 cambio de stado de la OT")
				let rcvn_1 = await StatusToRevcn({wo_folio:wo_folio,status:2})//cambia el stado de al OT;1:proceso, 2:revision, 3:finalizada, 4:cancelada
				// console.log("2 cerrar la OT sin observaciones => paso a revision",{id:rcvn_1.data.id, status:rcvn_1.data.id_status_work_order})
				// let rcvn_2 = await StatusToRevcn({wo_folio:wo_folio,status:4})//cambia el stado de al OT;1:proceso, 2:revision, 3:finalizada, 4:cancelada
				// console.log("3 cerrar la OT sin observaciones => paso a finalizado",rcvn_2)
				console.log({id:rcvn_1.data.id, status:rcvn_1.data.id_status_work_order})
				if(rcvn_1.success){
					console.log("status true")
					await ClosedOt({wo_folio, rut:LoginState.rut, user:LoginState.user, status:rcvn_1.data.id_status_work_order})
					setState({ ...state, ['right']: !state.right });
					ObtainOtsApi()
				}else{
					alert('Error al cerrar la OT, intentelo mas tarde')
				}
				// {id: 12732054, wo_folio: 'OT218669', id_status_work_order: 2}
			}
		});
		
	}

	/** guarda la anota en la OT */
	const SaveNoteInOT = async (wofolio) => {
		// console.log(NotaEdit, wofolio);
		await Put_AddNote({wo_folio:wofolio, comment: NotaEdit})
	}

	/** lanzadores, iniciadores y actuadores */
	useEffect(() => {
		ObtainOtsApi()
		return () => {
			ObtainOtsApi()
		}
	}, [])

	useEffect(() => {
		let obj_ = {}
		const makeIsMakeSoliItem = async () => {
			for (let ii = 0; ii < TasksBault.length; ii++) {
				// console.log("========>",ii);
				obj_[ii] = 0//({...isMakeSoli,[ii]:0})
			}
			setisMakeSoli(obj_)
			// console.log(obj_)
		}
		makeIsMakeSoliItem()
		return () => {
			makeIsMakeSoliItem()
		}
	}, [TasksBault])

	useEffect(() => {
		if(OtsSelected[0]?.note){
			// console.log(OtsSelected[0]?.note);
			setNotaEdit(OtsSelected[0]?.note)
		}

	}, [OtsSelected])
	
  return (
    <>
		<HeaderMenu Pag_Sel={'ot'} VisibleModal={VisibleModal} setVisibleModal={setVisibleModal} />
		
		<div className='container'>
			<div className='row'>
				{/* ESPACEADOR */}
				<div className='col-2'></div>
				{/* OTS EN PROCESO */}
					<div className='col-12 col-md-8 mb-3'>
						<div className='row'>
							<div className='col'>
								<div className='row'>
									<div className='col-12 card-header card-shadows text-center mb-4 pt-3'>
										<div className='row p-0 m-0'>
											<div className='col-10'><h5><TimelineIcon fontSize='large' color='warning'/>  OTs en Proceso ({OtsBault.length??"0"})</h5></div>
											<div className='col-2 pt-1 '><span className='puntero' onClick={()=>{
													ObtainOtsApi()
													setFechaFilter('')
												}}><RefreshIcon /></span></div>
											<div className='filter-ot'>
												{
													MenuFilter?<span className='puntero' onClick={()=>setMenuFilter(!MenuFilter)}><FilterAltOffIcon color='error'/></span>:
													<span className='puntero' onClick={()=>setMenuFilter(!MenuFilter)}><FilterAltIcon/></span>
												}
											</div>
										</div>
									</div>
									{/* filtro para OT's */}
											<div className={MenuFilter?'menu-filter':'menu-filter-none'}>
												<div className=''>
													<div className='col-12'>
														<span>Filtro <FilterAltIcon fontSize='16'/></span>
													</div>
													<div className='col-12 mt-2 py-3'>
														<input
															type='date'
															className='inputFecha'
															value={FechaFilter}
															onChange={(e)=>setFechaFilter(e.target.value)}
														/>
														<span style={{position: 'absolute', left: 20, top: 4, paddingInline: 4, fontSize: "small", backgroundColor: 'white'}}>Fecha</span>
													</div>
													
													<div className='col-12 mt-2 py-3'>
														<input
															type='text'
															className='inputFecha'
															value={OTFilter}
															onChange={(e)=>setOTFilter(e.target.value)}
														/>
														<span style={{position: 'absolute', left: 20, top: 4, paddingInline: 4, fontSize: "small", backgroundColor: 'white'}}>Ingrese N° OT</span>
													</div>
													<div className='col-12 text-right'>
														<button className='btn btn-sm btn-danger mt-2 mr-3' onClick={()=>{
																// ObtainOtsApi()
																setOtsBault(OtsBaultBKP)
																setFechaFilter('')
																setOTFilter('')
																setMenuFilter(!MenuFilter)
															}}>Limpiar Filtro</button>
														<button className='btn btn-sm btn-info mt-2' onClick={()=>FiltroPorFecha()}>Filtrar</button>
													</div>
												</div>
											</div>
									<div className='col-12 card-shadows card-itm-ot card-body'>
										<div className='row px-2'>

										{/* ot card a repetir por cada ot encontrada del usuario */}
										{
											OtsBault.length>=1?
											OtsBault.map(ots => (
												<div key={ots.wo_folio} className='col-12 ot-item mb-3' >
													<div className='row py-2 px-2'>
														<div className='col-12 p-0 m-0 puntero' onClick={()=>MenuCrud(ots.wo_folio,ots)}>
															<div className='row py-2 px-2'>
																{
																	ots.personnel_path_image?<div className='col-2 d-flex p-0 m-0'><img className='cont-avatar' src={'/favicon.png'}/></div>
																	:<div className='col-2 text-center'><Avatar>
																		{String(ots.user_assigned).split(" ")[0].substring(0,1)}
																		{String(ots.user_assigned).split(" ").length===4?
																			String(ots.user_assigned).split(" ")[2].substring(0,1)
																			:String(ots.user_assigned).split(" ")[1].substring(0,1)
																		}
																	</Avatar></div>
																}
																
																<div className='col-10'>
																	<Typography component={'p'} className='font-weight-normal text-truncate' style={{paddingBottom: 0, marginBottom:0}}>{ots.user_assigned}</Typography>
																	<label className='pm-0'>{String(ots.cal_date_maintenance).split("T")[0]} / {
																		ots.duration/60>59?
																		(Math.floor(Math.trunc((ots.duration/60)/60))>=10?
																		Math.floor(Math.trunc((ots.duration/60)/60))
																		:"0"+Math.floor(Math.trunc((ots.duration/60)/60)))+":"+((ots.duration/60)-(60*(Math.floor(Math.trunc((ots.duration/60)/60)))))
																		:"00:"+ots.duration/60
																	}</label>
																</div>
																<div className='col-10 pt-2'>
																	<div className="progress" style={{borderRadius: 25, height: 10 }}>
																		<div className="progress-bar" role="progressbar" style={{width:`${ots?.completed_percentage}%`}} aria-valuenow={ots?.completed_percentage} aria-valuemin="0" aria-valuemax="100"></div>
																	</div>
																</div>
																<div className='col-2'>
																	<small>{ots?.completed_percentage}%</small>
																</div>
																<div className='col-12 text-truncate'>
																	<small style={{color:"#8C8C8C", fontWeight: '600'}}>Creada por {ots.created_by}</small>
																</div>
																<div className='col-12 text-truncate'>
																	<Typography component={'span'}>{ots.items_log_description}</Typography>
																</div>
																<div className='col-12 mt-2' style={{color: "#4A4A4A"}}>
																	<Typography component={'span'} data-toggle="tooltip" title={`Activo: ${ots.items_log_description}`}>
																		<IoLayersOutline className='text-primary'/>
																	</Typography>
																	<label className='small'>1</label><label className='pl-3'></label>
																	<Typography component={'span'} data-toggle="tooltip" title={`Tareas: ${ots.items_log_description}`}>
																		<FaListCheck className='text-primary'/>
																	</Typography>
																	<label className='small'>1</label>
																</div>
															</div>
														</div>

														<div className='col-12 p-0 m-0'>
															<div className='row py-2 px-2'>
																<div className='col-12'>
																	<hr className='separador' style={{padding: 0, margin:0}}/>
																</div>
																<div className='col-4 pt-1' style={{marginBottom: 0}}>
																	<Typography component={'span'} className='lead font-weight-normal' style={{marginBottom: 0}}>{ots.wo_folio}</Typography>
																</div>
																<div className='col-8 text-right pt-1' style={{marginBottom: 0}}>
																	<span className='puntero' onClick={()=>GenerarPDF_NOW(ots)}><PiDownloadBold size={30} color='#A49D9D'/></span>
																	{/* <button className='btn btn-sm btn-danger ml-3' onClick={()=>HandleChangeStatus(ots.wo_folio)}>Finalizar OT</button> */}
																</div>
															</div>
														</div>
													</div>
												</div>
											))
											:<div className='col-12 text-center mt-3'><Typography component={'span'} className='lead'>SIN OTS ASIGNADAS AUN</Typography></div>
										}

										
										</div>
										
									</div>
								</div>
							</div>
						</div>
					</div>
				{/* ESPACEADOR */}
				<div className='col-2'></div>
			</div>
		</div>
		<br/>

		{/* GENERAR COMPONENETE CON EL DRAWER PARA EVITAR BUG DE REDUNDANCIA */}
		{/* DRAWER MENU CRUD */}
		<Drawer
			anchor={'right'}
			open={state['right']}
			onClose={toggleDrawer('right', false)}
			id='drawer-crud-basofia'
		>
        <div className='container-crudmenu mb-5 mx-2'>
				{/*  header menu crud */}
					<div className='shdowBan fixed-drud'>
						<div className='row header-crud py-2'>
							<div className='col-2'>
								<ArrowBackIosIcon color='info' onClick={toggleDrawer('right', false)}/>
							</div>
							<div className='col-7' onClick={()=>console.log(OBSOT)}>
								Orden de trabajo
							</div>
							{/* 
							BOTON PARA GUARDAR LOS DATOS, GENERAR CUADRO DE DIALOGO PREGUNTANDO LAS DIFERENCIAS SI ES QUE ESTAS EXISTEN
							DE LO CONTRARIO GUARDAR LOS DATOS Y PASAR LA OT DIRECTAMENTE A FINALIZADA
							 */}
							<div className='col-3 text-center'>
								{/* <SaveIcon color='success'	 /> */}
								<button className='btn btn-sm btn-success' onClick={()=>HandleChangeStatus(OtsSelected[0].wo_folio)}>Cerrar OT</button>
							</div>
						</div>
					</div>
					<div className='spacin-crud'></div>
					{/* CRUD OTs */}
								{/* {console.log(OtsSelected)} */}
					<div className='container-fluid mt-3 mb-3'>
						<div className='row card-ot-crud m-1 pb-2 shdowBan'>
							{/* <div className='col-2 d-flex p-1 m-0'>
								<img className='cont-avatar' src={'/favicon.png'}/>
							</div> */}

								{
									OtsSelected.length?
									OtsSelected[0].personnel_path_image?<div className='col-2 d-flex p-0 m-0'><img className='cont-avatar' src={'/favicon.png'}/></div>
									:<div className='col-2 pt-2 text-center'><Avatar>
										{String(OtsSelected[0].user_assigned).split(" ")[0].substring(0,1)}
										{String(OtsSelected[0].user_assigned).split(" ").length===4?
											String(OtsSelected[0].user_assigned).split(" ")[2].substring(0,1)
											:String(OtsSelected[0].user_assigned).split(" ")[1].substring(0,1)
										}
									</Avatar></div>
									:null
								}

							<div className='col-8' data-toggle="tooltip">
								<div className='row pt-1'>
									<div className='col-12 text-01 text-truncate'>{OtsSelected.length?OtsSelected[0].user_assigned:null}</div>
									<div className='col-12'><small>{OtsSelected.length?String(OtsSelected[0]?.cal_date_maintenance).split("T")[0]:null} / {OtsSelected.length?String(OtsSelected[0]?.cal_date_maintenance).split("T")[1].substring(0,5):null}</small></div>
								</div>
							</div>
							<div className='col-2 p-0 m-0'>
								<ExpandMoreIcon color='primary' style={{cursor: 'pointer'}}/>
								<TimelineIcon fontSize='large' color='warning'/>
							</div>
							<div className='col-12 text-truncate'>
								<Typography component={'span'} className='text-02'>Creada por {OtsSelected[0]?.created_by}</Typography>
							</div>
							<div className='col-12' title='78%'>
								<div className='row'>
									<div className='col-10 pt-2'>
										<div className="progress" style={{borderRadius: 25, height: 10 }}>
											<div className="progress-bar" role="progressbar" style={{width:`${OtsSelected[0]?.completed_percentage}%`}} aria-valuenow={OtsSelected[0]?.completed_percentage} aria-valuemin="0" aria-valuemax="100"></div>
										</div>
									</div>
									<div className='col-2'>
										<small>{OtsSelected[0]?.completed_percentage}%</small>
									</div>
								</div>
							</div>

							<div className='col-12 mt-2'>
								<TextField
									fullWidth
									id="outlined-multiline-static"
									label="Nota"
									multiline
									rows={2}
									value={NotaEdit}
									onChange={(e)=>setNotaEdit(e.target.value)}
								/>
							</div>
							<div className='col-12' title='OT253256'>
								<Typography component={'span'}>{OtsSelected.length?OtsSelected[0].wo_folio:null}</Typography>
							</div>
						</div>
					</div>

					{/* CRUD TAREAs */}
					<div className='container-fluid mt-3 mb-4'>
						<div className='row card-ot-crud m-1 pt-2 shdowBan'>
							<div className='col-12 py-2' style={{borderBottom: "1px solid #00A4E2"}}>
								<div className='row'>
									<div className='col-6'>
											<Typography component={'span'}>Tareas</Typography>
									</div>
									<div className='col-6 text-right'>
										<Typography component={'span'}>Total: {TasksBault.length} <ArrowUpwardIcon style={{color: "#9A9A9A"}} /></Typography>
									</div>
								</div>
							</div>
							

							{/* Repetir por cada tarea en ot */}
							{
								TasksBault&&TasksBault.map((tskkk, index) => <div key={index+10000001}><TaskComp 
									key={tskkk.id_work_orders_tasks}
									ModalLoadded={ModalLoadded}
									setModalLoadded={setModalLoadded}
									setTexto={setTexto}
									task={tskkk}
									/>
									{/* {console.log(tskkk)} */}
									{
										isMakeSoli[index]===1?
										<div className='col-12 my-3'>
											<div className='row'>

												<div className='col-10 mb-2'>
													<AsyncSelect
														isClearable
														isSearchable
														placeholder={'Buscar Activo'}
														value={SelMaq[index]}
														onChange={(e)=>setSelMaq({...SelMaq,[index]:e?e:''})}
														loadOptions={promiseOptionsEquipos}
													/>
												</div>

												<div className='col-2 mb-2'>
													<button disabled={SelMaq[index]===""||SelMaq===""?true:false} className='btn btn-sm btn-info' onClick={()=>addActiveToTextArea({index})}><AddIcon /></button>
												</div>
												{/* <span onClick={()=>console.log(SelMaqMulti[index])}>print</span> */}
												<div className='col-12'>
													<TextField
														focused
														style={{fontSize: 12}}
														fullWidth
														id="outlined-multiline-staticoem"
														label={`Descripción para la solicitud`}
														multiline
														rows={3}
														value={OBSOT[index]}
														onChange={(e)=>setOBSOT({...OBSOT,[index]:e.target.value.toUpperCase()})}
													/>
												</div>

												<div className='col-12'>
													<button
														disabled={OBSOT[index]?.length>=3 && SelMaq[index]===""?false:true}
														className='float-right btn btn-sm btn-info mt-2'
														onClick={()=>creaSolictudes({
															observacion: OBSOT[index],
															tarea: tskkk.description,
															agp: SelMaqMulti[index],//tskkk.items_log_description,
															tipo_tarea: tskkk.tasks_log_task_type_main,
															Clasificacion_2: tskkk.tasks_log_types_2_description,
															Clasificacion_1: tskkk.tasks_log_types_description,
															solicitante: tskkk.user_assigned,
															OT: tskkk.wo_folio,
															index
														})}
														>Crear Solicitud</button>
												</div>
											</div>
										</div>:
										isMakeSoli[index]===2?
											<div className='col-12 text-center bg-danger mt-2' style={{borderRadius:8, cursor:'pointer'}}>
												<span
													className='text-white'>
													<b>Solicitud Creada, 
														<a
															style={{color:'yellow'}}
															onClick={()=>setisMakeSoli({...isMakeSoli,[index]:1})}
														> Desea crear otra Solicitud?
														</a>
													</b>
												</span>
											</div>:null
									}
								</div>)
							}
							{/* Repetir por cada tarea en ot */}

							
						</div>
					</div>
        </div>
				
      </Drawer>

			 {/** modal loading */}
			 {
				 ModalLoadded?<ModalLoaded texto={Texto} />:null
			 }

			 <div style={VisibleModal?{display:'block',position: 'fixed',top: '0px',left: '0px',width:'100%', height: '100vh', backgroundColor: 'rgb(0,0,0,0.8)',zIndex:'100000000000000000'}:{display:'none'}}>
					<ModalMakeMeAFileByDate VisibleModal={VisibleModal} setVisibleModal={setVisibleModal} />
				</div>

    </>
  )
}

