import React from 'react'
import { URL_SRV_ARIZTIA } from '../../router/Url';

export const SolicitudCreated = ({
    code,// AGP LIGADO A LA TAREA
    description,// OBSERVACIONES + NOMBREE DE LA TAREA
    comment,// NEGATICO + OBSERVACION OBLIGATORIA
    name,// NOMBRE USUARIO LOGUEADO
    reference
  }) => {
    let fecha = new Date();
  return new Promise(async (resolve, reject) => {
    let query = await fetch(URL_SRV_ARIZTIA+'save_solicitud_created',{
      method: 'POST',
      headers: {
        'authorization': "marcuxo",
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(
        {
          AGP:code,
          DESCRIPCION:description,
          COMENTARIO:comment,
          SOLICITANTE:name,
          OT:reference,
          ID_SOLICITUD: "100000001",
          FECHA_SOLICITUD: fecha.toISOString(),
          TIMESTAMP: fecha.getTime()
        }
      )
    })
    let responsito = await query.json();
    console.log(responsito);
    if(responsito.success){
      resolve(responsito.body)
    }else{
      resolve(null)
    }
  })
}
