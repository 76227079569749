// export const URL_LOGIN = "https://apimedidores.apidev.info/signin/"
// export const URL_FORGET = "https://apimedidores.apidev.info/forgetpassword/"


/**
 * FROM LOCAL SERVER
 */
// export const URL_LOGIN =       "http://localhost:4088/signin_vr/"
// export const URL_SRV =         "http://localhost:4088/app-medidor/"
// export const URL_FORGET =      "http://localhost:4088/forgetpassword/"
// export const URL_GETPLANTA =   "http://localhost:4088/app-medidor/getlistaempresas"
// export const URL_CTRL_HIDRIC = "http://localhost:4088/ctrl-hidrico/"
// export const URL_CTRL_TRASP =  "http://localhost:4088/ctrl-transporte/"
// export const URL_SRV_RAIZ =    "http://localhost:4088/"
// export const URL_SRV_ARIZTIA = "http://localhost:4088/ariztia/"


/**
 * FROM REMOTE APISERVER
 */
export const URL_LOGIN =        "https://apimedidores.apidev.info/signin_vr/" // consulta en la base de datos de los usuarios de fracttal
export const URL_SRV =          "https://apimedidores.apidev.info/app-medidor/"
export const URL_FORGET =       "https://apimedidores.apidev.info/forgetpassword/"
export const URL_GETPLANTA =    "https://apimedidores.apidev.info/app-medidor/getlistaempresas"
export const URL_CTRL_HIDRIC =  "https://apimedidores.apidev.info/ctrl-hidrico/"
export const URL_CTRL_TRASP =   "https://apimedidores.apidev.info/ctrl-transporte/"
export const URL_SRV_RAIZ =     "https://apimedidores.apidev.info/"
export const URL_SRV_ARIZTIA =  "https://apimedidores.apidev.info/ariztia/"

export const VERSION_APP =      "versión 4.18.17"

/** 
 * ENDPOINT API INTERNA FRACTTAL
*/
export const URL_GET_OTS =          "https://ariztock.apidev.info/api/fracttal/getwofolio?code_user="
export const URL_GET_TASKS =        "https://ariztock.apidev.info/api/fracttal/gettasks?wo_folio="
export const URL_GET_SUB_TASKS =    "https://ariztock.apidev.info/api/fracttal/getsubtasks?taskid="
export const URL_UPDATE_SUB_TASKS = "https://ariztock.apidev.info/api/fracttal/updatesubtask"
export const URL_ADD_REGISTRO =     "https://ariztock.apidev.info/api/fracttal/adddate"
export const URL_STATUS_TO_REVCN =  "https://ariztock.apidev.info/api/fracttal/status"
export const URL_CREA_SOLI =        "https://ariztock.apidev.info/api/fracttal/postrequest"
export const URL_GET_RECURSOH =     "https://ariztock.apidev.info/api/fracttal/getresources?wo_folio="
export const URL_ADD_RECURSOH =     "https://ariztock.apidev.info/api/fracttal/addresource"
export const URL_DEL_RECURSOH =     "https://ariztock.apidev.info/api/fracttal/delresource"
export const URL_UPLOAD_FILE =      "https://ariztock.apidev.info/api/fracttal/upload"
export const URL_GET_GETTIMES =     "https://ariztock.apidev.info/api/fracttal/gettimes?taskID="
export const URL_PUT_ADDNOTE =      "https://ariztock.apidev.info/api/fracttal/addnotes"

